import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import ReservationsPage from './ReservationsPage';
import AboutPage from './AboutPage';
import FAQPage from './FAQPage';
import Container from './Container';
// import Section from './Section';
// import Card from './Card';
import MoreInfo from './MoreInfo';

function App() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h1>
            <Link to="/">
              <img src='/logo_snappo.png' alt='snappo'></img>
            </Link>
          </h1>
          <nav>
            <button className="menu-button" onClick={toggleDropdown}>
              <span className="material-icons">menu</span>
            </button>
            <ul className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}>
              <li>
                <Link to="/reservations" onClick={() => setIsDropdownOpen(false)}>
                  Reservations
                </Link>
              </li>
              <li>
                <Link to="/eventhub" onClick={() => setIsDropdownOpen(false)}>
                  EventHub
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={() => setIsDropdownOpen(false)}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/faq" onClick={() => setIsDropdownOpen(false)}>
                  FAQ
                </Link>
              </li>
            </ul>
          </nav>
        </header>

        <main>
        <Routes>
          <Route path="/" element={<Container><HomePage /></Container>} />
          <Route path="/reservations" element={<Container><ReservationsPage /></Container>} />
          <Route path="/eventhub" element={<Container><MoreInfo /></Container>} />
          <Route path="/about" element={<Container><AboutPage /></Container>} />
          <Route path="/faq" element={<Container><FAQPage /></Container>} />
        </Routes>
        </main>


        <footer>
          <p>&copy; 2024 Snappo. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

const storage = getStorage();

function HomePage() {
  const [imageUrls, setImageUrls] = useState([]);

  const fetchImageUrls = async () => {
    const imagePaths = [
      "FCMImages/IMG_1.jpg",
      "FCMImages/IMG_2.jpg",
      "FCMImages/IMG_3.jpg",
      "FCMImages/IMG_4.jpg",
      "FCMImages/IMG_5.jpg",
      "FCMImages/IMG_6.jpg",
      "FCMImages/IMG_7.jpg",
      "FCMImages/IMG_8.jpg",
      "FCMImages/IMG_9.jpg",
      "FCMImages/IMG_10.jpg",
    ];
  
    try {
      const urls = await Promise.all(
        imagePaths.map(async (path) => {
          const imageRef = ref(storage, path);
          return await getDownloadURL(imageRef);
        })
      );
      setImageUrls(urls);
    } catch (error) {
      console.error("Error fetching image URLs:", error);
      return [];
    }
  };

  useEffect(() => {
    fetchImageUrls();
  }, []);

  const prompts = [
    "Snap a pic!",
    "Send us a pic of your travels",
    "How well do you know the bride or groom? Click here to take a quiz.",
    "Welcome to Snappo! Stay updated with event details here.",
    "The ceremony is about to begin, don't forget to silence your phones."
  ];

  const reviews = [
    "This app made my event so memorable!",
    "The photo-sharing feature is fantastic.",
    "Loved the personalized event hub. Highly recommend!",
    "I love how connected we felt the entire week!",
    "The photo-sharing feature is fantastic.",
  ];

  return (
    <div className="homepage">
      <h1>The Ultimate</h1>
      <h2>Private Social Platform</h2>
      <h3>Connect with your guests through a platform curated for your private event.</h3>
      <div className="smooth-carousel">
        <div className="carousel-track">
          {[...imageUrls, ...imageUrls, ...imageUrls, ...imageUrls].map((url, index) => (
            <div key={index} className="carousel-itm">
              <img src={url} alt={`Event ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

      <div className="smooth-carousel reviews-carousel">
        <div className="carousel-track">
          {[...prompts, ...prompts, ...prompts].map((prompt, index) => (
            <div key={index} className="prompt-slide">
              <p className="prompt-text">"{prompt}"</p>
            </div>
          ))}
        </div>
      </div>

      {/* <div className="card-features">
        <Card title="Connect" 
          description="Send mass texts and keep your guests engaged seamlessly.">
        </Card>
        <Card title="Share"
          description="Personalized social feeds to share moments in real-time.">
        </Card>
        <Card title="Remember"
          description="Receive a digital and physical photo album of your event memories.">
        </Card>
      </div> */}

      <div className="communication-tools">
        <div className="text-section">
          <h2>Connect</h2>
          <p>
            A platform to capture every perspective.
          </p>
          <h2>Share</h2>
          <p>
            Engage guests to capture media throughout your event.
          </p>
          <h2>Remember</h2>
          <p>
            A platform to capture every perspective.
          </p>
        </div>

        <div className="image-section">
          <img 
            className="phone-image"
            src="https://firebasestorage.googleapis.com/v0/b/vip-feed-d30d0.appspot.com/o/FCMImages%2FIMG_5982-removebg-preview.png?alt=media&token=824768f7-994b-4a46-83fb-b882e58308f5" 
            alt="Custom Social Feed on iPhone"
          />
        </div>
      </div>

      {/* <div className="communication-tools">
        <div className="image-section">
          <img 
            className="phone-image"
            src="https://firebasestorage.googleapis.com/v0/b/vip-feed-d30d0.appspot.com/o/FCMImages%2FIMG_5982-removebg-preview.png?alt=media&token=824768f7-994b-4a46-83fb-b882e58308f5" 
            alt="Custom Social Feed on iPhone"
          />
        </div>
        <div className="text-section">
          <h2>Share</h2>
          <p>
            A platform to capture every perspective.
          </p>
        </div>
      </div>

      <div className="communication-tools">
        <div className="text-section">
          <h2>Remember</h2>
          <p>
            A platform to capture every perspective.
          </p>
        </div>
      </div> */}

      <div className="smooth-carousel reviews-carousel">
        <div className="carousel-track">
          {[...reviews, ...reviews, ...reviews].map((review, index) => (
            <div key={index} className="review-slide">
              <p className="review-text">"{review}"</p>
            </div>
          ))}
        </div>
      </div>

    </div>

    
  );
}

export default App;