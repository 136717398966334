import React, { useState, useEffect, useCallback } from 'react';
import { firestore, collection, addDoc, query, where, doc, getDocs, deleteDoc, writeBatch } from './firebase-config';
import 'react-datepicker/dist/react-datepicker.css';
import './GuestList.css';
import Papa from "papaparse";

function GuestList({ eventCode }) {
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [guestList, setGuestList] = useState([]);
  const [newGuest, setNewGuest] = useState({ firstName: '', lastName: '', phoneNumber: '' });
  const [columns] = useState(['First Name', 'Last Name', 'Phone']);
  const [searchQueries, setSearchQueries] = useState({});
  const [editedGuestList, setEditedGuestList] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [csvError, setCsvError] = useState("");
  const [csvSuccess, setcsvSuccess] = useState('');

  // Fetch guest list data from Firestore
  const fetchGuestList = useCallback(async () => {
    try {
      const docPath = `events/${eventCode}/guestList`;
      const querySnapshot = await getDocs(collection(firestore, docPath));
      const guestListData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          lastName: data['Last Name'] || '', 
          firstName: data['First Name'] || '',
          phoneNumber: data['Phone'] || '',
          id: doc.id,
        };
      });

      // Sort by last name
      const sortedGuestList = guestListData.sort((a, b) => (a.lastName || '').localeCompare(b.lastName || ''));

      setGuestList(sortedGuestList);
      setEditedGuestList(sortedGuestList);
    } catch (error) {
      console.error('Error fetching guest list:', error);
    }
  }, [eventCode]);

  useEffect(() => {
    fetchGuestList();
    setSearchQueries({});
  }, [ fetchGuestList ]);

  const handleEditChange = (e, rowIdx, column) => {
    const updatedGuestList = [...editedGuestList];
    updatedGuestList[rowIdx] = {
      ...updatedGuestList[rowIdx],
      [column]: e.target.value,
    };
    setEditedGuestList(updatedGuestList);
    setIsEdited(true);
    setSuccessMessage('');
    setError('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGuest({ ...newGuest, [name]: value });
    setSuccessMessage('');
    setError('');
  };

  const handleAddGuest = async (e) => {
    e.preventDefault();

    const { firstName, lastName, phoneNumber } = newGuest;
    
    // Map input values to Firestore fields
    const firestoreGuestData = {
      'First Name': firstName,
      'Last Name': lastName,
      Phone: phoneNumber,
    };

    if (!firstName || !lastName || !phoneNumber) {
      setError('Please fill in all fields.');
      return;
    }

    try {
      const docPath = `events/${eventCode}/guestList`;
      
      const phoneQuery = query(
        collection(firestore, docPath),
        where('Phone', '==', phoneNumber)
      );
      const querySnapshot = await getDocs(phoneQuery);
  
      if (!querySnapshot.empty) {
        setError('Guest with this phone number already exists.');
        setSuccessMessage('');
        return;
      }

      await addDoc(collection(firestore, docPath), firestoreGuestData);
      setGuestList([...guestList, firestoreGuestData].sort((a, b) =>
        (a['Last Name'] || '').localeCompare(b['Last Name'] || '')
      ));
      setNewGuest({ firstName: '', lastName: '', phoneNumber: '' });
      setError('');
      setSuccessMessage('Guest added successfully!');
      await fetchGuestList();
    } catch (error) {
      console.error('Error adding guest:', error);
      setError('Error adding guest. Please try again.');
      setSuccessMessage('');
    }
  };

  const handleDeleteGuest = async (guestId) => {
    try {
      const docPath = `events/${eventCode}/guestList/${guestId}`;
      await deleteDoc(doc(firestore, docPath));
  
      // Remove guest from local state
      setGuestList(guestList.filter((guest) => guest.id !== guestId));
      setSuccessMessage('Guest deleted successfully!');
    } catch (error) {
      console.error('Error deleting guest:', error);
      setError('Error deleting guest. Please try again.');
    }
  };

  const handleSearchChange = (e, column) => {
    const { value } = e.target;
    setSearchQueries((prevQueries) => ({
      ...prevQueries,
      [column]: value.toLowerCase(),
    }));
  };  

  const filteredGuestList = editedGuestList.filter((guest) => {
    // Extract search queries
    const firstNameQuery = searchQueries['First Name'] || '';
    const lastNameQuery = searchQueries['Last Name'] || '';
    const phoneQuery = searchQueries['Phone Number'] || '';
  
    // Check if the guest matches the search queries
    const lastNameMatch = lastNameQuery
      ? guest.lastName.toLowerCase().includes(lastNameQuery)
      : true;

    const firstNameMatch = firstNameQuery
    ? guest.firstName.toLowerCase().includes(firstNameQuery)
    : true;
  
    const phoneMatch = phoneQuery
      ? guest.phoneNumber.toLowerCase().includes(phoneQuery)
      : true;
  
    return firstNameMatch && lastNameMatch && phoneMatch;
  });

  const handleSaveChanges = async () => {
    try {
      const docPath = `events/${eventCode}/guestList`;
      const batch = writeBatch(firestore);

      editedGuestList.forEach((guest) => {
        if (guest.id) {
          const docRef = doc(firestore, docPath, guest.id);
          batch.update(docRef, {
            'Last Name': guest.lastName,
            'First Name': guest.firstName,
            'Phone': guest.phoneNumber,
          });
        }
      });

      await batch.commit();

      setSuccessMessage('Guest list updated successfully!');
      setError('');
      setIsEdited(false);
      await fetchGuestList(); // Refresh the guest list
    } catch (error) {
      console.error('Error saving changes:', error);
      setError('Error saving changes. Please try again.');
      setSuccessMessage('');
    }
  };

  useEffect(() => {
    setEditedGuestList(guestList);
  }, [guestList]);

  const handleCsvUpload = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      setCsvError("No file selected.");
      return;
    }

    Papa.parse(file, {
      header: true, // Ensures that the first row of the CSV is treated as headers
      skipEmptyLines: true, // Skip empty rows
      complete: async (results) => {
        const parsedData = results.data;

        if (!parsedData || parsedData.length === 0) {
          setCsvError("The CSV file is empty or invalid.");
          return;
        }

        try {
          const collectionPath = `events/${eventCode}/guestList`;
          const guestPromises = parsedData.map((guest) => {
            // Ensure the required fields are present
            if (guest["First Name"] && guest["Last Name"] && guest["Phone"]) {
              const guestData = {
                "First Name": guest["First Name"],
                "Last Name": guest["Last Name"],
                Phone: guest["Phone"],
              };
              return addDoc(collection(firestore, collectionPath), guestData);
            } else {
            return null;            
            }
          });

          // Wait for all guests to be added to Firestore
          await Promise.all(guestPromises);
          setCsvError("");
          setcsvSuccess("CSV uploaded successfully!");
          fetchGuestList();
        } catch (error) {
          console.error("Error uploading CSV:", error);
          setCsvError("Failed to upload CSV. Please check the file format.");
        }
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
        setCsvError("Error parsing CSV file. Please try again.");
      },
    });
  };

  return (
    <div className="event-hub-page">

      <div className="csv-upload">
        <h2>Upload Guest List</h2>
        <label htmlFor="csvFileInput" className="custom-file-button">
          Select File
        </label>
        <input
          id="csvFileInput"
          type="file"
          accept=".csv"
          onChange={handleCsvUpload}
          style={{ display: "none" }}
        />
        {csvError && <p className="error-message">{csvError}</p>}
        {csvSuccess && <p className="success-message">{csvSuccess}</p>}
      </div>

      <div className="add-guest-form">
        <h2>Add New Guest</h2>
        <form onSubmit={handleAddGuest}>
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={newGuest.firstName}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={newGuest.lastName}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="phoneNumber"
            placeholder="Phone Number"
            value={newGuest.phoneNumber}
            onChange={handleInputChange}
          />
          <button type="add-guest">Submit</button>
        </form>
        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
      </div>

      {isEdited && (
        <div className="save-changes-container">
          <button onClick={handleSaveChanges} className="save-changes-button">
              Save Changes
          </button>
        </div>
      )}

      <table className="prompts-table">
        <thead>
          <tr>
            {columns.map((column, idx) => (
              <th key={idx}>
                {column}
                  <input
                    type="text"
                    placeholder={`Search`}
                    value={searchQueries[column] || ''}
                    onChange={(e) => handleSearchChange(e, column)}
                    className="search-input"
                  />
              </th>
            ))}
            <th className="narrow-column"></th>
          </tr>
        </thead>
        <tbody>
          {filteredGuestList.length > 0 ? (
            filteredGuestList.map((guest, rowIdx) => (
              <tr key={rowIdx}>
                <td>
                  <input
                    type="text"
                    value={guest.firstName || ''}
                    onChange={(e) => handleEditChange(e, rowIdx, 'firstName')}
                    className="editable-cell"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={guest.lastName || ''}
                    onChange={(e) => handleEditChange(e, rowIdx, 'lastName')}
                    className="editable-cell"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={guest.phoneNumber || ''}
                    onChange={(e) => handleEditChange(e, rowIdx, 'phoneNumber')}
                    className="editable-cell"
                  />
                </td>
                <td className="narrow-column">
                  <div className="delete-guest-button-container">
                    <button onClick={() => handleDeleteGuest(guest.id)} className="delete-guest-button">
                      <span className="material-icons">delete_outline</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length}>No guest list available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default GuestList;
