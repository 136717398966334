import React, { useState } from 'react';
import './ReservationsPage.css';
import { firestore, setDoc, getDoc, doc, Timestamp } from './firebase-config';

function ReservationsPage() {
  const [eventName, setEventName] = useState('');
  const [eventDescr, setEventDescr] = useState('');
  const [hostName, sethostName] = useState('');
  const [hostPhone, sethostPhone] = useState('');
  const [hostEmail, sethostEmail] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
  
    const formattedEventName = eventName.trim().replace(/\s+/g, '-').toLowerCase();
    const eventDocRef = doc(firestore, 'requests', formattedEventName);
  
    try {
      const eventDoc = await getDoc(eventDocRef);
      if (eventDoc.exists()) {
        setMessage('Event with this name already exists. Please choose a different name.');
        setLoading(false);
        return;
      }
  
      const startTimestamp = Timestamp.fromDate(new Date(startDate));
      const endTimestamp = Timestamp.fromDate(new Date(endDate));
      const eventUrl = `https://app.snappo.io/${formattedEventName}`;   

      await setDoc(eventDocRef, {
        eventName: formattedEventName,
        eventUrl,
        hostName,
        hostPhone,
        hostEmail,
        startDate: startTimestamp,
        endDate: endTimestamp,
        createdAt: new Date(),
        eventDescr,
      });
  
      setMessage('Reservation submitted!');
      console.log(message);
      // Clear form inputs
      setEventName('');
      setEventDescr('');
      sethostEmail('');
      sethostPhone('');
      sethostName('');
      setStartDate('');
      setEndDate('');
    } catch (error) {
      setMessage('Error submitting reservation. Please try again.');
      console.error('Error adding document: ', error);
    } finally {
      setLoading(false);
    }
  };  

  return (
    <section id="features-reservations">
      <div className="reservation-form">
        <h3>Request Form</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="event-name">Event Title:</label>
            <input
              type="text"
              id="event-name"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              placeholder="Enter your event title"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="event-descr">Event Description:</label>
            <textarea
              type="text"
              id="event-descr"
              value={eventDescr}
              onChange={(e) => setEventDescr(e.target.value)}
              placeholder="Event Description"
              required
              rows="4"
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="event-name">Host:</label>
            <input
              type="text"
              id="host-name"
              value={hostName}
              onChange={(e) => sethostName(e.target.value)}
              placeholder="Name"
              required
            />
            <input
              type="email"
              id="host-email"
              value={hostEmail}
              onChange={(e) => sethostEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <input
              type="phone"
              id="host-phone"
              value={hostPhone}
              onChange={(e) => sethostPhone(e.target.value)}
              placeholder="Phone Number"
            />
          </div>
          <div className="form-group">
            <label htmlFor="start-date">Start Date:</label>
            <input
              type="date"
              id="start-date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
              placeholder='Enter Start Date'
            />
          </div>
          <div className="form-group">
            <label htmlFor="end-date">End Date:</label>
            <input
              type="date"
              id="end-date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
              placeholder='Enter End Date'
            />
          </div>
          <button type="submit" disabled={loading || !eventName || !hostName || !startDate || !endDate}>
            {loading ? 'Submitting...' : 'Submit Reservation'}
          </button>

        </form>
        {message && <p className="message">{message}</p>}
      </div>
    </section>
  );
}

export default ReservationsPage;