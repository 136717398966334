import React, { useState } from 'react';
import './MoreInfo.css';
import { firestore, collection, getDocs, addDoc, deleteDoc, doc, updateDoc, getDoc } from './firebase-config';
import SignUpForm from './SignUpForm';
import GuestList from './GuestList';
import Prompts from './Prompts';

function MoreInfo() {
  const [eventCode, setEventCode] = useState('');
  const [isEventCodeValid, setIsEventCodeValid] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [itinerary, setItinerary] = useState([]);
  const [keyPeople, setKeyPeople] = useState([]);
  const [keyLocations, setKeyLocations] = useState([]);
  const [activeTab, setActiveTab] = useState('itinerary');
  const [isSaving, setIsSaving] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [host, setHost] = useState(false); 
  const [user, setUser] = useState(null); 

  const handleEventCodeSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');

    const eventDocRef = doc(firestore, 'events', eventCode);
    const eventDoc = await getDoc(eventDocRef);

    if (eventDoc.exists()) {
      // const data = eventDoc.data();
      setIsEventCodeValid(true);
    } else {
      setErrorMessage('Invalid event code. Please try again.');
    }

    setLoading(false);
  };
  
  const handleUserSignIn = async (user) => {
    // const user = auth.currentUser;
    setUser(user);

    if (!user) {
      setErrorMessage('You need to sign in.');
      return;
    }

    try {
      const userDocRef = doc(firestore, `events/${eventCode}/profiles`, user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setHost(userData.host);
        await fetchEventData();
      } else {
        console.log('You are not registered for this event.');
      }
    } catch (error) {
      console.log('An error occurred while checking user registration.');
      console.error('Error:', error);
    }
  }; 

  // Function to save all changes for a given section
  const saveSectionToFirestore = async (sectionData, collectionPath) => {
    setIsSaving(true);
    try {
      const promises = sectionData.map((item) => {
        const updatedItem = {
          ...item,
          date: item.date ? firestore.Timestamp.fromDate(item.date) : null,
        };
        const docRef = doc(firestore, collectionPath, item.id);
        return updateDoc(docRef, updatedItem);
      });
      await Promise.all(promises);
      console.log(`${collectionPath} successfully updated!`);
    } catch (error) {
      console.error("Error updating section: ", error);
    } finally {
      setIsSaving(false);
    }
  };

  const addInputCard = async (type) => {
    setIsAdding(true);
  
    const defaultValues = {
      itinerary: { 
        date: "", 
        eventName: "", 
        eventLocation: "" },
      
        keyLocations: { 
        name: "", 
        address: "No Address", 
        details: "" },
      
        keyPeople: { 
        name: "", 
        role: "No Role", 
        phoneNumber: "", 
        email: "" },
    };
  
    try {
      // Ensure eventCode is valid
      if (!eventCode) {
        console.error("Event code is not set.");
        return;
      }
  
      const collectionPath = `events/${eventCode}/${type}`;
      const newDocRef = await addDoc(collection(firestore, collectionPath), defaultValues[type]);
      console.log(`New ${type} document created with ID:`, newDocRef.id);
  
      // Update the local state with the new document
      const newItem = { id: newDocRef.id, ...defaultValues[type] };
  
      if (type === "itinerary") {
        setItinerary((prev) => {
          let noDateGroup = prev.find(([date]) => date === "No Date");
          if (!noDateGroup) {
            noDateGroup = ["No Date", []];
            prev.push(noDateGroup);
          }
          noDateGroup[1].push(newItem);
          return [...prev];
        });
      } else if (type === "keyLocations") {
        setKeyLocations((prev) => {
          const updatedLocations = [...prev, newItem];
          // Sort locations, ensuring "No Address" is at the bottom
          return updatedLocations.sort((a, b) => {
            if (a.address === "No Address") return 1;
            if (b.address === "No Address") return -1;
            return 0;
          });
        });
      } else if (type === "keyPeople") {
        setKeyPeople((prev) => {
          const updatedPeople = [...prev, newItem];
          // Sort people, ensuring "No Role" is at the bottom
          return updatedPeople.sort((a, b) => {
            if (a.role === "No Role") return 1;
            if (b.role === "No Role") return -1;
            return 0;
          });
        });
      }
    } catch (error) {
      console.error(`Error creating new ${type} document:`, error);
    } finally {
      await fetchEventData();
      setIsAdding(false);
    }
  };

  const handleDeleteItem = async (id, type) => {
    try {
      // Define the collection path
      const collectionPath = `events/${eventCode}/${type}`;
  
      // Delete the document from Firestore
      await deleteDoc(doc(firestore, collectionPath, id));
  
      console.log(`${type} document with ID: ${id} deleted successfully`);
  
      // Update the local state
      if (type === "itinerary") {
        setItinerary((prev) =>
          prev.map(([date, events]) => [
            date,
            events.filter((event) => event.id !== id),
          ])
        );
      } else if (type === "keyLocations") {
        setKeyLocations((prev) => prev.filter((location) => location.id !== id));
      } else if (type === "keyPeople") {
        setKeyPeople((prev) => prev.filter((person) => person.id !== id));
      }
    } catch (error) {
      console.error(`Error deleting ${type} document:`, error);
    }
  };
  
  
  const fetchEventData = async () => {
    try {
      const locationsPath = `events/${eventCode}/keyLocations`;
      const locationsRef = collection(firestore, locationsPath);
      const locationsSnapshot = await getDocs(locationsRef);
      const locationsData = locationsSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name || '',
        address: doc.data().address || '',
        details: doc.data().description || '',
        date: doc.data().date ? new Date(doc.data().date.seconds * 1000) : null,
      }));

      const sortedLocations = locationsData.sort((a, b) => {
        if (a.address === "No Address") return 1;
        if (b.address === "No Address") return -1;
        return 0;
      });
      setKeyLocations(sortedLocations);

      const peoplePath = `events/${eventCode}/keyPeople`;
      const peopleRef = collection(firestore, peoplePath);
      const peopleSnapshot = await getDocs(peopleRef);
      const peopleData = peopleSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name || '',
        role: doc.data().role || '',
        phone: doc.data().phoneNumber || '',
        email: doc.data().email || '',
      }));
      const sortedPeople = peopleData.sort((a, b) => {
        if (a.role === "No Role") return 1;
        if (b.role === "No Role") return -1;
        return 0;
      });
      setKeyPeople(sortedPeople);

      const itineraryPath = `events/${eventCode}/itinerary`;
      const itineraryRef = collection(firestore, itineraryPath);
      const itinerarySnapshot = await getDocs(itineraryRef);

      const groupedItinerary = {};
      itinerarySnapshot.docs.forEach((doc) => {
        const data = doc.data();
        const eventDate = data.date ? new Date(data.date.seconds * 1000) : null;

        const formattedDate = eventDate
          ? eventDate.toLocaleDateString('en-US', {
              weekday: 'long',
              month: 'long',
              day: 'numeric',
            })
          : 'No Date';

        const formattedTime = eventDate
          ? eventDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
          : 'No time';
        
        if (!groupedItinerary[formattedDate]) {
          groupedItinerary[formattedDate] = [];
        }

        groupedItinerary[formattedDate].push({
          id: doc.id,
          eventName: data.eventName || 'No title',
          eventLocation: locationsData.find((loc) => loc.id === data.eventLocation)?.name || 'Unknown Location',
          time: formattedTime,
          contactName: peopleData.find((person) => person.id === data.contact)?.name || '',
          contactPhone: peopleData.find((person) => person.id === data.contact)?.phone || '',
          date: eventDate,
        });
      });

      // Sort grouped itinerary by date, placing "No Date" last
      const sortedGroupedItinerary = Object.entries(groupedItinerary).sort(([dateA], [dateB]) => {
        if (dateA === "No Date") return 1; // Place "No Date" last
        if (dateB === "No Date") return -1; // Keep "No Date" below other dates
        return new Date(dateA) - new Date(dateB); // Sort other dates normally
      });

      setItinerary(sortedGroupedItinerary);

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderEditableDateTime = (date, onChange) => {
    const formattedDateTime = date
      ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
      : '';
  
    return (
      <input
        type="datetime-local"
        value={formattedDateTime}
        onChange={(e) => onChange(new Date(e.target.value))}
        className="editable-input"
      />
    );
  };
  

  const renderEditable = (value, onChange, placeholder, type) => (
    <div className="edit-input-field">
      <input
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="editable-input"
        placeholder={placeholder}
      />
    </div>
  );

  const renderItinerary = () => (
    <div className="itinerary-container">
      {itinerary.length > 0 ? (
        itinerary.map(([date, events], index) => (
          <div className="day-section" key={index}>
            <h2>{date}</h2>
            <div className="events-container">
              {events.map((event, idx) => (
                <div className="event-card" key={idx}>
                  <div className="event-time">
                    <span className="material-icons-outlined">schedule</span>
                    {renderEditableDateTime(event.date, (newDate) =>
                      setItinerary((prev) => {
                        const updatedItinerary = [...prev];
                        updatedItinerary[index][1][idx].date = newDate; // Update the date
                        return updatedItinerary;
                      })
                    )}
                  </div>
                  <div className="event-details">
                    <div>
                      {renderEditable(event.eventName, (value) =>
                        setItinerary((prev) => {
                          const newItinerary = [...prev];
                          newItinerary[index][1][idx].eventName = value;
                          return newItinerary;
                        }),
                      "Enter event name", "text"
                      )}
                    </div>
                    <div>
                      <span className="material-icons-outlined">location_on</span>
                      {renderEditable(event.eventLocation, (value) =>
                        setItinerary((prev) => {
                          const newItinerary = [...prev];
                          newItinerary[index][1][idx].eventLocation = value;
                          return newItinerary;
                        }),
                      "Enter event location", "text"
                      )}
                    </div>
                  </div>
                  <button 
                    className="delete-button" 
                    onClick={() => handleDeleteItem(event.id, "itinerary")}>
                    Delete
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <div>No itinerary data available.</div>
      )}
      <button className="add-button" onClick={() => addInputCard("itinerary")} disabled={isAdding}>
        Add New Itinerary
      </button>
      <button className="save-button" onClick={() => saveSectionToFirestore(itinerary, `events/${eventCode}/itinerary`)} disabled={isSaving}>
        {isSaving ? "Saving..." : "Save"}
      </button>
    </div>
  );

  const renderKeyPeople = () => (
    <div className="key-people-container">
      {keyPeople.map((person, index) => (
        <div className="person-card" key={index}>
          <div>
          {/* <strong>Name:</strong>{' '} */}
            {renderEditable(person.name, (value) =>
              setKeyPeople((prev) => {
                const newPeople = [...prev];
                newPeople[index].name = value;
                return newPeople;
              }),
              "Enter contact name", "text"
            )}
          </div>
          <div>
            {/* <strong>Role:</strong>{' '} */}
            {renderEditable(person.role, (value) =>
              setKeyPeople((prev) => {
                const newPeople = [...prev];
                newPeople[index].role = value;
                return newPeople;
              }),
              "Enter contact role", "text"
            )}
          </div>
          <div>
            <span className="material-icons-outlined">contact_phone</span>
            {renderEditable(person.phone, (value) =>
              setKeyPeople((prev) => {
                const newPeople = [...prev];
                newPeople[index].phone = value;
                return newPeople;
              }),
              "Enter contact phone", "phone"
            )}
          </div>
          <div>
            <span className="material-icons-outlined">email</span>
            {renderEditable(person.email, (value) =>
              setKeyPeople((prev) => {
                const newPeople = [...prev];
                newPeople[index].email = value;
                return newPeople;
              }),
              "Enter contact email", "email"
            )}
          </div>
          <button 
            className="delete-button" 
            onClick={() => handleDeleteItem(person.id, "keyPeople")}>
            Delete
          </button>
        </div>
      ))}
      <button className="add-button" onClick={() => addInputCard("keyPeople")} disabled={isAdding}>
        Add New Contact
      </button>
      <button className="save-button" onClick={() => saveSectionToFirestore(keyPeople, `events/${eventCode}/keyPeople`)} disabled={isSaving}>
        {isSaving ? "Saving..." : "Save"}
      </button>
    </div>
  );

  const renderKeyLocations = () => (
    <div className="key-locations-container">
      {keyLocations.map((location, index) => (
        <div className="location-card" key={index}>
          <div>
            {renderEditable(location.name, (value) =>
              setKeyLocations((prev) => {
                const newLocations = [...prev];
                newLocations[index].name = value;
                return newLocations;
              }),
              "Enter location name", "text"
            )}
          </div>
          <div>
            <span className="material-icons-outlined">location_on</span>
            {renderEditable(location.address, (value) =>
              setKeyLocations((prev) => {
                const newLocations = [...prev];
                newLocations[index].address = value;
                return newLocations;
              }),
              "Enter location address", "address"
            )}
          </div>
          <div>{renderEditable(location.details, (value) =>
            setKeyLocations((prev) => {
              const newLocations = [...prev];
              newLocations[index].details = value;
              return newLocations;
            }),
              "Enter location description", "text"
          )}
          </div>
          <button 
            className="delete-button" 
            onClick={() => handleDeleteItem(location.id, "keyLocations")}>
            Delete
          </button>
        </div>
      ))}
      <button className="add-button" onClick={() => addInputCard("keyLocations")} disabled={isAdding}>
        Add New Location
      </button>
      <button className="save-button" onClick={() => saveSectionToFirestore(keyLocations, `events/${eventCode}/keyLocations`)} disabled={isSaving}>
        {isSaving ? "Saving..." : "Save"}
      </button>
    </div>
  );

  return (
    <section id="features-moreinfo">
      {!isEventCodeValid ? (
      <div className="enter-event-code">
        <h2>Enter Event Code</h2>
        <form onSubmit={handleEventCodeSubmit}>
          <input
            type="text" 
            value={eventCode}
            onChange={(e) => {
              setEventCode(e.target.value);
              setErrorMessage('');
            }}
            placeholder="Enter your event code"
          />
          <button className="event-code-submit-button" type="submit" disabled={loading}>
            <span className="material-icons">send</span>
            {loading ? 'Verifying...' : ''}
          </button>
        </form>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
      ) : !user ? (
        <SignUpForm eventCode={eventCode} onSignUpSuccess={handleUserSignIn}/>
      ) : host ? (
        <div className="itinerary-page">
          <div className="tabs-itinerary">
            <button className={`tab-button-itinerary ${activeTab === 'itinerary' ? 'active' : ''}`} 
              onClick={() => setActiveTab('itinerary')}>
              <span className={activeTab === 'itinerary' ? 'material-icons' : 'material-icons-outlined'}>calendar_today</span>              
              {/* Itinerary */}
            </button>
            <button className={`tab-button-itinerary ${activeTab === 'people' ? 'active' : ''}`} 
              onClick={() => setActiveTab('people')}>
              <span className={activeTab === 'people' ? 'material-icons' : 'material-icons-outlined'}>contact_phone</span>              
              {/* Contacts */}
            </button>
            <button className={`tab-button-itinerary ${activeTab === 'locations' ? 'active' : ''}`} 
              onClick={() => setActiveTab('locations')}>
              <span className={activeTab === 'locations' ? 'material-icons' : 'material-icons-outlined'}>place</span>              
              {/* Locations */}
            </button>
            <button className={`tab-button-itinerary ${activeTab === 'guestList' ? 'active' : ''}`} 
              onClick={() => setActiveTab('guestList')}>
              <span className={activeTab === 'guestList' ? 'material-icons' : 'material-icons-outlined'}>people</span>              
              {/* Guest List */}
            </button>
            <button className={`tab-button-itinerary ${activeTab === 'prompts' ? 'active' : ''}`} 
              onClick={() => setActiveTab('prompts')}>
              <span className="material-icons">{activeTab === 'prompts' ? 'chat_bubble' : 'chat_bubble_outline'}</span>
              {/* Prompts */}
            </button>
          </div>
          <div className="itinerary-content">
            {activeTab === 'itinerary' && renderItinerary()}
            {activeTab === 'people' && renderKeyPeople()}
            {activeTab === 'locations' && renderKeyLocations()}
            {activeTab === 'eventhub' && renderItinerary()}
            {activeTab === "guestList" && <GuestList eventCode={eventCode} />}
            {activeTab === "prompts" && <Prompts eventCode={eventCode} />}
            </div>
        </div>
      ) : (
        <div>Welcome, user! You are not a host for this event.</div>
      )}
    </section>
  );
}

export default MoreInfo;
