import React from 'react';
import './AboutPage.css'

function AboutPage() {
  return (
    <section id="about">
      {/* <h2>About Us</h2> */}
      <p>At Snappo, we believe that the most memorable moments in life are best experienced together. 
        We created Snappo to give couples a personalized way to communicate with their guests, 
        ensuring that their special day is celebrated and remembered through the eyes of everyone involved.
      </p>
      <p>Beyond just communication, Snappo also fosters connection between guests, 
        allowing them to engage with one another and potentially create new friendships.
         Whether it's sending important updates, capturing candid photos, or sparking conversations, 
         Snappo enhances the wedding experience by putting the couple and their guests in touch with 
         one another in a fun and interactive way.
      </p>  
    </section>
  );
}

export default AboutPage;
