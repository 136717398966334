import React from 'react';
import './FAQPage.css'

function FAQPage() {
  return (
    <section id="faq">
      <h2>FAQ</h2>
      <p>info@snappo.io</p>
    </section>
  );
}

export default FAQPage;
